import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';
import { fSwissNumber } from '#/utils/formatNumber';

type ContractualAgreementsOutputProps = {
  have_children: boolean;
  spouse_disposal_right: boolean;
  disposal_fixed_amount: number;
  disposal_percentage: number;
};

export default function ContractualAgreementsOutput({
  have_children,
  disposal_fixed_amount,
  disposal_percentage,
  spouse_disposal_right,
}: ContractualAgreementsOutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text style={styles.title}>
        3{' '}
        {String(
          translate(
            'marriageContract.stepFour.document.contractualAgreements.title'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>3.1</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.contractualAgreements.section1.firstParagraph'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>3.2</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.contractualAgreements.section2.firstParagraph'
          )
        )}{' '}
        {have_children &&
          String(
            translate(
              'marriageContract.stepFour.document.contractualAgreements.section2.nonCommonChildren'
            )
          )}
      </Text>
      <Text style={{ marginTop: 4 }}>
        {String(
          translate(
            'marriageContract.stepFour.document.contractualAgreements.section2.pointA'
          )
        )}
      </Text>
      <Text style={{ marginTop: 4 }}>
        {String(
          translate(
            'marriageContract.stepFour.document.contractualAgreements.section2.pointB'
          )
        )}
      </Text>
      {spouse_disposal_right && (
        <>
          <Text style={{ marginBottom: 4, marginTop: 10 }}>3.4</Text>
          <Text>
            {String(
              translate(
                'marriageContract.stepFour.document.contractualAgreements.section4.firstParagraph',
                {
                  disposal_fixed_amount: fSwissNumber(disposal_fixed_amount),
                  disposal_percentage,
                }
              )
            )}
          </Text>
        </>
      )}
    </View>
  );
}
