import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';
import { ContractParty } from '#/types/marriageContract';
import useLocales from '#/hooks/useLocales';

interface PersonalInfo extends ContractParty {
  gender: string;
  date_of_birth: string;
  civil_status: string;
}

type PartiesOutputProps = {
  user: PersonalInfo;
  spouseInfo: ContractParty;
  address: string;
  spouseAddress: string;
};

export default function PartiesOutput({
  user,
  spouseInfo,
  address,
  spouseAddress,
}: PartiesOutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section}>
      <Text>
        {String(
          translate('marriageContract.stepFour.document.spousePresentation', {
            spouseLastName: spouseInfo?.first_name,
            spouseName: spouseInfo?.last_name,
            relation: translate(
              `marriageContract.stepFour.document.${
                user?.gender === 'M' ? 'male' : 'female'
              }`
            ),
            spouseBirthDate: fDate(spouseInfo?.birthday, 'dd. MMM yyyy', 'de'),
            spousePlaceOfCitizenship: spouseInfo?.place_of_citizenship,
            spouseCivilStatus: String(
              translate(
                `marriageContract.stepFour.document.civilStatus.${user?.civil_status}`
              )
            ).toLowerCase(),
            spouseAddress,
          })
        )}
      </Text>
      {String(translate('marriageContract.stepFour.document.and'))}
      <Text>
        {String(
          translate('marriageContract.stepFour.document.personalPresentation', {
            firstName: user?.first_name,
            lastName: user?.last_name,
            relation: translate(
              `marriageContract.stepFour.document.${
                user?.gender === 'M' ? 'female' : 'male'
              }`
            ),
            birthDate: fDate(user?.date_of_birth, 'dd. MMM yyyy', 'de'),
            placeOfCitizenship: user?.place_of_citizenship,
            civilStatus: String(
              translate(
                `marriageContract.stepFour.document.civilStatus.${user?.civil_status}`
              )
            ).toLowerCase(),
            address,
          })
        )}
      </Text>
    </View>
  );
}
