import { useForm } from 'react-hook-form';
import React, { useEffect, useMemo } from 'react';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '#/hooks/useLocales';
import {
  invalidateMarriageContractQueries,
  useAddProperty,
  useEditProperty,
} from '#/api/marriageContract';
import { IProperty } from '#/types/marriageContract';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  isEditMode?: boolean;
  isAddMode?: boolean;
  property?: IProperty;
  resetEdit?: () => void;
  resetAddMode?: () => void;
};

const countries = [{ code: 'CH', label: 'global.switzerland' }];
const propertyTypes = [
  {
    value: 'apartment',
  },
  {
    value: 'house',
  },
  {
    value: 'chalet',
  },
  {
    value: 'rustico',
  },
  {
    value: 'building_plot',
  },
  {
    value: 'multi_family_house',
  },
  {
    value: 'commercial_building',
  },
  {
    value: 'residential_building',
  },
  {
    value: 'commercial_property',
  },
];

export default function PropertyForm({
  property,
  isEditMode,
  isAddMode,
  resetEdit,
  resetAddMode,
}: Props) {
  const { translate } = useLocales();

  const { mutateAsync: addProperty, isLoading: isAdding } = useAddProperty();
  const { mutateAsync: updateProperty, isLoading: isUpdating } =
    useEditProperty(property?.id);

  const defaultValues = useMemo(
    () => ({
      property_type: property?.property_type || '',
      address: {
        city: property?.address.city || '',
        street: property?.address.street || '',
        house_number: property?.address.house_number || '',
        zip_code: property?.address.zip_code || '',
        country: property?.address.country || 'CH',
      },
    }),
    [property]
  );

  const methods = useForm({ defaultValues });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = methods;

  const fieldNames = {
    'address.street': 'route',
    'address.house_number': 'street_number',
    'address.zip_code': 'postal_code',
    'address.city': 'locality',
  };

  const isStreetError = Boolean(errors?.address?.street);

  const disableSubmit = !isDirty;

  const onSubmit = async (data: any) => {
    try {
      if (isEditMode) {
        await updateProperty(data).then(() => {
          invalidateMarriageContractQueries.getProperties();
          resetEdit?.();
        });
      } else {
        await addProperty(data).then(() => {
          invalidateMarriageContractQueries.getProperties();
          resetAddMode?.();
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property) {
      reset(defaultValues);
    }
  }, [property]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          '& > *': {
            marginBottom: '1rem',
          },
        }}
      >
        <RHFSelect
          name="property_type"
          label={String(translate('global.formLabels.propertyType'))}
        >
          {propertyTypes.map((propertyType) => (
            <MenuItem key={propertyType.value} value={propertyType.value}>
              {String(
                translate(
                  `marriageContract.propertyTypes.${propertyType.value}`
                )
              )}
            </MenuItem>
          ))}
        </RHFSelect>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            sx={{
              width: 1,
            }}
          >
            <AutoAddressPicker
              fieldNames={fieldNames}
              streetError={isStreetError}
              name="address.street"
            />
          </Box>
          <RHFTextField
            name="address.house_number"
            label={String(translate('global.formLabels.houseNumber'))}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <RHFTextField
            name="address.zip_code"
            label={String(translate('global.formLabels.postalCode'))}
          />
          <RHFTextField
            name="address.city"
            label={String(translate('global.formLabels.city'))}
          />
        </Stack>
        <RHFSelect
          name="address.country"
          label={String(translate('global.formLabels.country'))}
          value={countries.find((country) => country.code === 'CH')?.code}
        >
          {countries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {String(translate(country.label))}
            </MenuItem>
          ))}
        </RHFSelect>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mt: 2 }}
        spacing={1}
      >
        <LoadingButton
          loading={isAdding || isUpdating}
          disabled={disableSubmit}
          variant="contained"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          startIcon={<Iconify icon="mdi:content-save" fontSize="small" />}
        >
          <Typography>{String(translate('global.saveDetails'))}</Typography>
        </LoadingButton>
        {(isEditMode || isAddMode) && (
          <Button
            startIcon={<Iconify icon="mdi:close" fontSize="small" />}
            onClick={() => resetEdit?.() || resetAddMode?.()}
          >
            <Typography>{String(translate('global.cancel'))}</Typography>
          </Button>
        )}
      </Stack>
    </FormProvider>
  );
}
