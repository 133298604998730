import { Document, Page } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import IntroOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/intro-output';
import PartiesOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/parties-output';
import DeterminationsOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/determinations-output';
import MarriageAgreementsOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/marriage-agreements-output';
import JointProvisionsOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/joint-provisions-output';
import ContractualAgreementsOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/contractual-agreements-output';
import SignatureOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/signature-output';
import NotarizationOutput from '#/components/pages/MarriageContract/steps/DocumentOutput/notarization-output';

type OutputProps = {
  outputValues?: any;
};

export default function Output({ outputValues }: OutputProps) {
  const { translate } = useLocales();
  const {
    user,
    spouseInfo,
    date_of_marriage,
    place_of_marriage,
    have_children,
    acquired_after_marriage,
    acquired_property_after_marriage,
    maintain_ordinary_property_regime,
    legally_optimal_choice,
    spouse_disposal_right,
    disposal_fixed_amount,
    disposal_percentage,
    children,
    personalAssets,
    spouseAssets,
    properties,
  } = outputValues;

  const address = `${user?.address?.street} ${user?.address
    ?.house_number}, ${user?.address?.zip_code} ${user?.address
    ?.city}, ${String(
    translate(`global.${user?.address?.country.toLowerCase()}`)
  )}`;

  const spouseAddress = `${spouseInfo?.address?.street} ${spouseInfo?.address
    ?.house_number}, ${spouseInfo?.address?.zip_code} ${spouseInfo?.address
    ?.city}, ${String(
    translate(`global.${spouseInfo?.address?.country.toLowerCase()}`)
  )}`;

  return (
    <Document
      pageMode="fullScreen"
      title={String(translate('global.services.marriageContract'))}
    >
      <Page size="A4" style={styles.page}>
        <IntroOutput user={user} address={address} />
        <PartiesOutput
          user={user}
          spouseInfo={outputValues?.spouseInfo}
          address={address}
          spouseAddress={spouseAddress}
        />
        <DeterminationsOutput
          date_of_marriage={date_of_marriage}
          place_of_marriage={place_of_marriage}
          have_children={have_children}
          acquired_after_marriage={acquired_after_marriage}
          acquired_property_after_marriage={acquired_property_after_marriage}
          childrens={children}
          personalAssets={personalAssets}
          spouseAssets={spouseAssets}
          properties={properties}
        />
        {maintain_ordinary_property_regime && <MarriageAgreementsOutput />}
      </Page>

      {legally_optimal_choice && (
        <Page size="A4" style={styles.page}>
          <ContractualAgreementsOutput
            have_children={have_children}
            spouse_disposal_right={spouse_disposal_right}
            disposal_fixed_amount={disposal_fixed_amount}
            disposal_percentage={disposal_percentage}
          />
        </Page>
      )}
      <Page size="A4" style={styles.page}>
        <JointProvisionsOutput />
        <SignatureOutput
          user={user}
          spouseInfo={spouseInfo}
          address={address}
          spouseAddress={spouseAddress}
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <NotarizationOutput address={address} />
      </Page>
    </Document>
  );
}
