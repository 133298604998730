import { Box, MenuItem, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import {
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';

const countries = [{ code: 'CH', label: 'global.switzerland' }];

export function SpouseInfoForm() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldNames = {
    'spouse.address.street': 'route',
    'spouse.address.house_number': 'street_number',
    'spouse.address.zip_code': 'postal_code',
    'spouse.address.city': 'locality',
  };

  // @ts-ignore
  const isStreetError = Boolean(errors?.spouse?.address?.street);

  const { translate } = useLocales();

  return (
    <Box
      display="block"
      sx={{
        '& > *': {
          marginBottom: '1rem',
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="spouse.first_name"
          label={String(translate('global.formLabels.firstName'))}
        />
        <RHFTextField
          name="spouse.last_name"
          label={String(translate('global.formLabels.lastName'))}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            width: 1,
          }}
        >
          <RHFDatePicker
            name="spouse.birthday"
            label={String(translate('global.formLabels.date_of_birth'))}
            disableFuture
          />
        </Box>
        <RHFTextField
          name="spouse.place_of_citizenship"
          label={String(translate('global.formLabels.place_of_citinzenship'))}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ width: '100%' }}>
          <AutoAddressPicker
            fieldNames={fieldNames}
            streetError={isStreetError}
            name="spouse.address.street"
          />
        </Stack>
        <RHFTextField
          name="spouse.address.house_number"
          label={String(translate('global.formLabels.houseNumber'))}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="spouse.address.zip_code"
          label={String(translate('global.formLabels.postalCode'))}
        />
        <RHFTextField
          name="spouse.address.city"
          label={String(translate('global.formLabels.city'))}
        />
      </Stack>
      <RHFSelect
        name="spouse.address.country"
        label={String(translate('global.formLabels.country'))}
        value={countries.find((country) => country.code === 'CH')?.code}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {String(translate(country.label))}
          </MenuItem>
        ))}
      </RHFSelect>
    </Box>
  );
}

export function PersonalInfoForm() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldNames = {
    'self.address.street': 'route',
    'self.address.house_number': 'street_number',
    'self.address.zip_code': 'postal_code',
    'self.address.city': 'locality',
  };

  // @ts-ignore
  const isStreetError = Boolean(errors?.self?.address?.street);

  const { translate } = useLocales();

  return (
    <Box
      display="block"
      sx={{
        '& > *': {
          marginBottom: '1rem',
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="self.first_name"
          label={String(translate('global.formLabels.firstName'))}
        />
        <RHFTextField
          name="self.last_name"
          label={String(translate('global.formLabels.lastName'))}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            width: 1,
          }}
        >
          <RHFDatePicker
            name="self.birthday"
            label={String(translate('global.formLabels.date_of_birth'))}
            disableFuture
          />
        </Box>
        <RHFTextField
          name="self.place_of_citizenship"
          label={String(translate('global.formLabels.place_of_citinzenship'))}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ width: '100%' }}>
          <AutoAddressPicker
            fieldNames={fieldNames}
            streetError={isStreetError}
            name="self.address.street"
          />
        </Stack>
        <RHFTextField
          name="self.address.house_number"
          label={String(translate('global.formLabels.houseNumber'))}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="self.address.zip_code"
          label={String(translate('global.formLabels.postalCode'))}
        />
        <RHFTextField
          name="self.address.city"
          label={String(translate('global.formLabels.city'))}
        />
      </Stack>
      <RHFSelect
        name="self.address.country"
        label={String(translate('global.formLabels.country'))}
        value={countries.find((country) => country.code === 'CH')?.code}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {String(translate(country.label))}
          </MenuItem>
        ))}
      </RHFSelect>
    </Box>
  );
}
