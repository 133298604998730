import { Stack, Typography } from '@mui/material';
import { RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import Child from '#/components/pages/LastWill/steps/content/Inheritors/childrens/Children/Child';
import { useGetChildren } from '#/api/childrenQueries';

export default function ChildrenCount() {
  const { translate } = useLocales();

  const { data: children } = useGetChildren();

  const noChildren = children?.length === 0;

  return (
    <Stack
      sx={{
        '& > *': {
          marginBottom: '1rem',
        },
      }}
    >
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {String(translate('global.howMany'))}
      </Typography>
      <RHFTextField
        disabled={!noChildren}
        name="number_of_children"
        label={String(translate('global.formLabels.children_count'))}
        type="number"
      />
      {/* {!!errors && ( */}
      {/*  <FormHelperText error={errors} sx={{ mx: 0 }}> */}
      {/*    {!!errors && String(translate('validations.marriage.childrenAmount'))} */}
      {/*  </FormHelperText> */}
      {/* )} */}
      <Child isLivingPartnership="false" />
    </Stack>
  );
}
