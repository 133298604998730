import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { estateQuestions } from '#/components/pages/MarriageContract/stepsData';
import useLocales from '#/hooks/useLocales';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from '#/components/pages/PensionProvision/styles';
import { IMarriageContract } from '#/types/marriageContract';
import Iconify from '#/components/shared/ui/Iconify';

export default function Estate() {
  const { translate } = useLocales();
  const { watch, setValue, control } = useFormContext();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const handleDefaultValue = (question: string) => {
    const value = watch(`${question}` as keyof IMarriageContract);
    if (value === null) return null;
    return value === true ? 'yes' : 'no';
  };

  const handleOnChange = (
    questionKey: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const isTrue = value === 'yes';
    if (!isTrue) {
      setValue(`${questionKey}`, false, { shouldValidate: true });
    } else setValue(questionKey, true, { shouldValidate: true });
  };

  const showChildren = (question: string) => {
    const value = watch(`${question}` as keyof IMarriageContract);
    return value === true;
  };

  const translatedQuestions = estateQuestions.map((question, i) => ({
    ...question,
    question: String(
      translate(`marriageContract.stepThree.questions.${i}.question`)
    ),
    answers: question.answers?.map((answer, j) => ({
      ...answer,
      label: String(
        translate(
          `marriageContract.stepThree.questions.${i}.options.${j}.label`
        )
      ),
    })),
  }));
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Stack spacing={5}>
        {translatedQuestions.map((question, index) => (
          <div
            key={question.questionKey}
            ref={(ref) => {
              refs.current[`${question.questionKey}`] = ref;
            }}
          >
            <Controller
              name={question.questionKey}
              control={control}
              render={({ fieldState: { error } }) => (
                <StyledRadioGroup
                  name={question.questionKey}
                  value={handleDefaultValue(question?.questionKey)}
                  onChange={(e) => {
                    handleOnChange(question.questionKey, e);
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                  >
                    <Typography variant="subtitle1">
                      {String(question.question)}
                    </Typography>
                    {(question.id === 2 ||
                      question.id === 4 ||
                      question.id === 6 ||
                      question.id === 7) && (
                      <CustomTooltip
                        title={String(
                          translate(
                            `marriageContract.stepThree.tooltips.id${question.id}`
                          )
                        )}
                      >
                        <Iconify
                          icon="octicon:info-24"
                          sx={{ color: 'primary.main', fontSize: '1.5rem' }}
                        />
                      </CustomTooltip>
                    )}
                  </Stack>

                  {question.answers && (
                    <Stack
                      direction={{
                        xs: 'column',
                        md: 'row',
                      }}
                      flexDirection="row"
                      spacing={2}
                    >
                      {question?.answers?.map((option) => (
                        <StyledFormControlLabel
                          key={`step-${question.id}-${option.value}`}
                          value={option.value}
                          control={<StyledRadio />}
                          label={String(option.label)}
                        />
                      ))}
                    </Stack>
                  )}
                  {!!error && (
                    <FormHelperText error={!!error} sx={{ mx: 0 }}>
                      {error && String(translate(error?.message))}
                    </FormHelperText>
                  )}
                </StyledRadioGroup>
              )}
            />
            <Stack sx={{ mt: 1 }}>
              {!question.answers && question.children && (
                <>{question.children.content()}</>
              )}
              {question.children && showChildren(`${question.questionKey}`) && (
                <>{question.children.content()}</>
              )}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  // @ts-ignore
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 'normal',
    padding: '0.5rem',
  },
}));
