import { Stack, Box } from '@mui/material';
import { RHFDatePicker, RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';

export default function MarriageInfoForm() {
  const { translate } = useLocales();

  return (
    <Stack direction="row" spacing={2}>
      <RHFTextField
        name="place_of_marriage"
        label={String(translate('global.formLabels.place_of_marriage'))}
      />
      <Box
        sx={{
          width: '100%',
        }}
      >
        <RHFDatePicker
          name="date_of_marriage"
          label={String(translate('global.formLabels.date_of_marriage'))}
          disableFuture
        />
      </Box>
    </Stack>
  );
}
