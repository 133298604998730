import * as Yup from 'yup';
import { IMarriageContract } from '#/types/marriageContract';
import { AuthUser } from '#/types/auth';

export const schemaValues = (
  user: AuthUser,
  initialValues: IMarriageContract,
  activeStep: number,
  childrenNo: number | undefined
) => ({
  spouse: {
    first_name: initialValues?.contract_parties[0]?.first_name ?? '',
    last_name: initialValues?.contract_parties[0]?.last_name ?? '',
    birthday: initialValues?.contract_parties[0]?.birthday ?? null,
    place_of_citizenship:
      initialValues?.contract_parties[0]?.place_of_citizenship ?? '',
    type: 'spouse',
    address: {
      city: initialValues?.contract_parties[0]?.address.city ?? '',
      country: initialValues?.contract_parties[0]?.address.country ?? 'CH',
      house_number:
        initialValues?.contract_parties[0]?.address.house_number ?? '',
      street: initialValues?.contract_parties[0]?.address.street ?? '',
      zip_code: initialValues?.contract_parties[0]?.address.zip_code ?? '',
    },
  },
  self: {
    type: 'self',
    first_name:
      (initialValues?.contract_parties[1]?.first_name || user?.first_name) ??
      '',
    last_name:
      (initialValues?.contract_parties[1]?.last_name || user?.last_name) ?? '',
    birthday:
      (initialValues?.contract_parties[1]?.birthday || user?.date_of_birth) ??
      null,
    place_of_citizenship:
      initialValues?.contract_parties[1]?.place_of_citizenship ?? '',
    address: {
      city:
        (initialValues?.contract_parties[1]?.address.city ||
          user?.address?.city) ??
        '',
      country:
        (initialValues?.contract_parties[1]?.address.country ||
          user?.address?.country) ??
        'CH',
      house_number:
        (initialValues?.contract_parties[1]?.address.house_number ||
          user?.address?.house_number) ??
        '',
      street:
        (initialValues?.contract_parties[1]?.address.street ||
          user?.address?.street) ??
        '',
      zip_code:
        (initialValues?.contract_parties[1]?.address.zip_code ||
          user?.address?.zip_code) ??
        '',
    },
  },
  place_of_marriage: initialValues?.place_of_marriage ?? '',
  date_of_marriage: initialValues?.date_of_marriage ?? null,
  have_children:
    childrenNo && childrenNo > 0 ? true : initialValues?.have_children ?? null,
  number_of_children:
    childrenNo && childrenNo > 0
      ? childrenNo
      : initialValues?.number_of_children ?? null,
  acquired_after_marriage: initialValues?.acquired_after_marriage ?? null,
  acquired_property_after_marriage:
    initialValues?.acquired_property_after_marriage ?? null,
  maintain_ordinary_property_regime:
    initialValues?.maintain_ordinary_property_regime ?? null,
  legally_optimal_choice: initialValues?.legally_optimal_choice ?? null,
  spouse_disposal_right: initialValues?.spouse_disposal_right ?? null,
  disposal_fixed_amount: initialValues?.disposal_fixed_amount ?? null,
  disposal_percentage: initialValues?.disposal_percentage ?? null,
  executor: initialValues?.executor ?? null,
  deputy: initialValues?.deputy ?? null,
  step: activeStep,
});

export const stepOneSchema = Yup.object().shape({
  spouse: Yup.object().shape({
    first_name: Yup.string().required('validations.user.first_name'),
    last_name: Yup.string().required('validations.user.last_name'),
    birthday: Yup.date()
      .required('validations.user.date_of_birth.required')
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        'validations.user.date_of_birth.max'
      ),
    place_of_citizenship: Yup.string().required(
      'validations.user.place_of_citinzenship'
    ),
    address: Yup.object().shape({
      city: Yup.string().required('validations.adress.city'),
      house_number: Yup.string().required('validations.adress.house_number'),
      street: Yup.string().required('validations.adress.street'),
      zip_code: Yup.string().required('validations.adress.zip_code'),
    }),
  }),
  self: Yup.object().shape({
    first_name: Yup.string().required('validations.user.first_name'),
    last_name: Yup.string().required('validations.user.last_name'),
    birthday: Yup.date()
      .required('validations.user.date_of_birth.required')
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        'validations.user.date_of_birth.max'
      ),
    place_of_citizenship: Yup.string().required(
      'validations.user.place_of_citinzenship'
    ),
    address: Yup.object().shape({
      city: Yup.string().required('validations.adress.city'),
      house_number: Yup.string().required('validations.adress.house_number'),
      street: Yup.string().required('validations.adress.street'),
      zip_code: Yup.string().required('validations.adress.zip_code'),
    }),
  }),
});

export const stepTwoSchema = (childrenNo: number | undefined) =>
  Yup.object().shape({
    place_of_marriage: Yup.string().required(
      'validations.marriage.place_of_marriage'
    ),
    date_of_marriage: Yup.date().required(
      'validations.marriage.date_of_marriage'
    ),
    have_children: Yup.boolean().required('validations.marriage.have_children'),
    number_of_children: Yup.string().when('have_children', {
      is: (value: boolean) => value,
      then: (schema) =>
        schema
          .required('validations.marriage.number_of_children')
          .typeError('validations.shouldBeNumber')
          .test(
            'is-less-than-children',
            'validations.marriage.is_less_than_children',
            (value) => parseInt(value, 10) === childrenNo
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  });

export const stepThreeSchema = Yup.object().shape({
  acquired_after_marriage: Yup.boolean().required(
    'validations.marriage.acquired_after_marriage'
  ),
  acquired_property_after_marriage: Yup.boolean().required(
    'validations.marriage.acquired_property_after_marriage'
  ),
  maintain_ordinary_property_regime: Yup.boolean().required(
    'validations.marriage.maintain_ordinary_property_regime'
  ),
  legally_optimal_choice: Yup.boolean().required(
    'validations.marriage.maintain_ordinary_property_regime'
  ),
  spouse_disposal_right: Yup.boolean().required(
    'validations.marriage.spouse_disposal_right'
  ),
  disposal_fixed_amount: Yup.number()
    .typeError('validations.shouldBeNumber')
    .when('spouse_disposal_right', {
      is: (value: boolean) => value,
      then: (schema) =>
        schema.required('validations.marriage.disposal_fixed_amount'),
      otherwise: (schema) => schema.notRequired(),
    }),
  disposal_percentage: Yup.number()
    .typeError('validations.shouldBeNumber')
    .when('spouse_disposal_right', {
      is: (value: boolean) => value,
      then: (schema) =>
        schema.required('validations.marriage.disposal_percentage'),
      otherwise: (schema) => schema.notRequired(),
    }),
  // executor: Yup.string().required('validations.marriage.executor'),
  // .test(
  //   'is-not-self',
  //   'validations.marriage.sameAsSelf',
  //   (value) => !isSameAsSelf
  // )
  // .test(
  //   'is-not-spouse',
  //   'validations.marriage.sameAsSpouse',
  //   (value) => !isSameAsSpouse
  // ),
  // deputy: Yup.string().required('validations.marriage.deputy'),
  // .test(
  //   'is-not-self',
  //   'validations.marriage.sameAsSelf',
  //   (value) => !isSameAsSelf
  // )
  // .test(
  //   'is-not-spouse',
  //   'validations.marriage.sameAsSpouse',
  //   (value) => !isSameAsSpouse
  // ),
});

export const allValuesReset = {
  spouse: {
    first_name: '',
    last_name: '',
    birthday: null,
    place_of_citizenship: '',
    type: 'spouse',
    address: {
      city: '',
      country: 'CH',
      house_number: '',
      street: '',
      zip_code: '',
    },
  },
  self: {
    type: 'self',
    first_name: '',
    last_name: '',
    birthday: null,
    place_of_citizenship: '',
    address: {
      city: '',
      country: 'CH',
      house_number: '',
      street: '',
      zip_code: '',
    },
  },
  place_of_marriage: '',
  date_of_marriage: null,
  have_children: null,
  number_of_children: null,
  acquired_after_marriage: null,
  acquired_property_after_marriage: null,
  maintain_ordinary_property_regime: null,
  legally_optimal_choice: null,
  spouse_disposal_right: undefined,
  disposal_fixed_amount: null,
  disposal_percentage: null,
  executor: null,
  deputy: null,
};
