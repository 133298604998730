import ContractParties from '#/components/pages/MarriageContract/steps/ContractParties/ContractParties';
import {
  SpouseInfoForm,
  PersonalInfoForm,
} from '#/components/pages/MarriageContract/steps/ContractParties/general-information';
import Marriage from '#/components/pages/MarriageContract/steps/Marriage/Marriage';
import MarriageInfoForm from '#/components/pages/MarriageContract/steps/Marriage/marriage-info-form';
import Estate from '#/components/pages/MarriageContract/steps/Estate/Estate';
import ChildrenCount from '#/components/pages/MarriageContract/steps/Marriage/children-info-form';
import Assets from '#/components/pages/MarriageContract/steps/Estate/assets_before_marriage';
import PropertyAfterMarriage from '#/components/pages/MarriageContract/steps/Estate/property_after_marriage';
import DisposableAmountForm from '#/components/pages/MarriageContract/steps/Estate/disposable-amount/disposable-amount-form';
import DocumentOutput from '#/components/pages/MarriageContract/steps/DocumentOutput';

export const stepsData = [
  {
    content: () => <ContractParties />,
  },
  {
    content: () => <Marriage />,
  },
  {
    content: () => <Estate />,
  },
  {
    content: () => <DocumentOutput />,
  },
];

export const contractPartiesQuestions = [
  {
    id: 1,
    questionKey: 'spouse',
    children: {
      content: () => <SpouseInfoForm />,
    },
  },
  {
    id: 2,
    questionKey: 'self',
    children: {
      content: () => <PersonalInfoForm />,
    },
  },
];

export const marriageQuestions = [
  {
    id: 1,
    questionKey: 'marriage_details',
    children: {
      content: () => <MarriageInfoForm />,
    },
  },
  {
    id: 2,
    questionKey: 'have_children',
    answers: [{ value: 'yes' }, { value: 'no' }],
    children: {
      questionKey: 'number_of_children',
      content: () => <ChildrenCount />,
    },
  },
];

export const estateQuestions = [
  {
    id: 1,
    questionKey: 'assets_before_marriage',
    children: {
      content: () => <Assets />,
    },
  },
  {
    id: 2,
    questionKey: 'acquired_after_marriage',
    answers: [{ value: 'yes' }, { value: 'no' }],
  },
  {
    id: 3,
    questionKey: 'acquired_property_after_marriage',
    answers: [{ value: 'yes' }, { value: 'no' }],
    children: {
      content: () => <PropertyAfterMarriage />,
    },
  },
  {
    id: 4,
    questionKey: 'maintain_ordinary_property_regime',
    answers: [{ value: 'yes' }, { value: 'no' }],
  },
  {
    id: 5,
    questionKey: 'legally_optimal_choice',
    answers: [{ value: 'yes' }, { value: 'no' }],
  },
  {
    id: 6,
    questionKey: 'spouse_disposal_right',
    answers: [{ value: 'yes' }, { value: 'no' }],
    children: {
      content: () => <DisposableAmountForm />,
    },
  },
  // {
  //   id: 7,
  //   questionKey: 'executor',
  //   children: {
  //     content: () => <ExecutorSelection />,
  //   },
  // },
  // {
  //   id: 8,
  //   questionKey: 'deputy',
  //   children: {
  //     content: () => <DeputySelection />,
  //   },
  // },
];
