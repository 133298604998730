import { View, Text } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';

type NotarizationOutputProps = {
  address: string;
};

export default function NotarizationOutput({
  address,
}: NotarizationOutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>
        5{' '}
        {String(
          translate('marriageContract.stepFour.document.notarization.title')
        )}
      </Text>
      <Text
        style={{
          marginBottom: 4,
        }}
      >
        {String(
          translate(
            'marriageContract.stepFour.document.notarization.firstParagraph'
          )
        )}
      </Text>
      <Text>
        -{' '}
        {String(
          translate(
            'marriageContract.stepFour.document.notarization.firstCondition'
          )
        )}
      </Text>
      <Text>
        -{' '}
        {String(
          translate(
            'marriageContract.stepFour.document.notarization.secondCondition'
          )
        )}
      </Text>
      <Text>
        -{' '}
        {String(
          translate(
            'marriageContract.stepFour.document.notarization.thirdCondition'
          )
        )}
      </Text>
      <Text style={{ marginTop: 10 }}>{address}</Text>
      <Text
        style={{
          marginTop: 60,
        }}
      >
        {String(
          translate(
            'marriageContract.stepFour.document.notarization.noterTitle'
          )
        )}
      </Text>
    </View>
  );
}
