import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { Stack, Box, MenuItem, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  invalidateMarriageContractQueries,
  useAddAsset,
  useEditAsset,
  useGetContractParties,
} from '#/api/marriageContract';
import Iconify from '#/components/shared/ui/Iconify';
import {
  fSwissThousandSeparator,
  resetSwissSeparator,
} from '#/utils/formatNumber';

type Props = {
  isEditMode?: boolean;
  isAddMode?: boolean;
  asset?: {
    id: number;
    description_good: string;
    approximate_value: string | number;
    storage_location: string;
    previous_owner: string | number;
  };
  resetEdit?: () => void;
  resetAddMode?: () => void;
};

export default function AssetsForm({
  asset,
  isEditMode,
  isAddMode,
  resetEdit,
  resetAddMode,
}: Props) {
  const { translate } = useLocales();

  const { mutateAsync: addAsset, isLoading: isAdding } = useAddAsset();
  const { mutateAsync: updateAsset, isLoading: isUpdating } = useEditAsset(
    asset?.id
  );
  const { data: contractParties } = useGetContractParties();

  const Schema = Yup.object().shape({
    description_good: Yup.string().required(
      String(translate('validations.marriage.assets.description_good'))
    ),
    approximate_value: Yup.string()
      .required(
        String(translate('validations.marriage.assets.approximate_value'))
      )
      .test(
        'is-valid-number',
        String(
          translate('validations.marriage.assets.approximate_value_number')
        ),
        (value) => !Number.isNaN(Number(resetSwissSeparator(value || '')))
      ),
    storage_location: Yup.string().required(
      String(translate('validations.marriage.assets.storage_location'))
    ),
    previous_owner: Yup.string().required(
      String(translate('validations.marriage.assets.previous_owner'))
    ),
  });

  const defaultValues = useMemo(
    () => ({
      description_good: asset?.description_good ?? '',
      approximate_value:
        fSwissThousandSeparator(asset?.approximate_value.toString() || '') ??
        '',
      storage_location: asset?.storage_location ?? '',
      previous_owner: asset?.previous_owner ?? '',
    }),
    [asset]
  );

  const methods = useForm({
    resolver: yupResolver(Schema) as any,
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue,
  } = methods;

  const disableSubmit = !isDirty;

  const onSubmit = async (data: any) => {
    try {
      const unformattedData = {
        ...data,
        approximate_value: resetSwissSeparator(data.approximate_value),
      };
      if (isEditMode) {
        await updateAsset(unformattedData).then(() => {
          invalidateMarriageContractQueries.getAssets();
          resetEdit?.();
        });
      } else {
        await addAsset(unformattedData).then(() => {
          invalidateMarriageContractQueries.getAssets();
          resetAddMode?.();
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (asset) {
      reset(defaultValues);
    }
  }, [asset]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={2}
          sx={{
            marginBottom: 2,
          }}
        >
          <RHFTextField
            name="description_good"
            label={String(translate('global.formLabels.good_description'))}
          />
          <RHFTextField
            name="approximate_value"
            label={String(translate('global.formLabels.approximate_value'))}
            onChange={(e) => {
              const formatted = fSwissThousandSeparator(e.target.value);
              setValue('approximate_value', formatted);
            }}
          />
          <RHFTextField
            name="storage_location"
            label={String(translate('global.formLabels.storage_location'))}
          />
        </Stack>
        <RHFSelect
          name="previous_owner"
          label={String(translate('global.formLabels.previous_owner'))}
        >
          {contractParties?.results?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.type === 'self'
                ? String(translate('global.contractParties.self'))
                : String(translate('global.contractParties.spouse'))}
            </MenuItem>
          ))}
        </RHFSelect>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mt: 2 }}
        spacing={1}
      >
        <LoadingButton
          loading={isAdding || isUpdating}
          disabled={disableSubmit}
          variant="contained"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          startIcon={<Iconify icon="mdi:content-save" fontSize="small" />}
        >
          <Typography>{String(translate('global.saveDetails'))}</Typography>
        </LoadingButton>
        {(isEditMode || isAddMode) && (
          <Button
            startIcon={<Iconify icon="mdi:close" fontSize="small" />}
            onClick={() => resetEdit?.() || resetAddMode?.()}
          >
            <Typography>{String(translate('global.cancel'))}</Typography>
          </Button>
        )}
      </Stack>
    </FormProvider>
  );
}
