import { Box, Button, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { useGetProperties } from '#/api/marriageContract';
import PropertyForm from '#/components/pages/MarriageContract/steps/Estate/property_after_marriage/property-form';
import PropertyDetails from '#/components/pages/MarriageContract/steps/Estate/property_after_marriage/property-details';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

export default function PropertyAfterMarriage() {
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editID, setEditID] = useState<number | null>(null);

  const { data: properties } = useGetProperties();

  const editProperty = properties?.results.find(
    (property) => property.id === editID
  );

  const isInitial = properties?.results.length === 0;

  const handleAddMode = () => {
    setAddMode(true);
    setEditMode(false);
  };

  const handleEditMode = (propertyID: number) => {
    setEditMode(true);
    setEditID(propertyID);
    setAddMode(false);
  };

  const { translate } = useLocales();

  return (
    <Box>
      {isInitial && <PropertyForm />}
      {addMode && (
        <PropertyForm
          isAddMode={addMode}
          resetAddMode={() => setAddMode(false)}
        />
      )}
      {editMode && (
        <PropertyForm
          isEditMode={editMode}
          property={editProperty}
          resetEdit={() => setEditMode(false)}
        />
      )}
      {!isInitial && !addMode && !editMode && (
        <div>
          {properties?.results.map((property, index) => (
            <Box key={index}>
              <PropertyDetails
                property={property}
                onEdit={() => handleEditMode(property?.id)}
              />
              {index !== (properties?.results || []).length - 1 && <Divider />}
            </Box>
          ))}
          <Button
            onClick={handleAddMode}
            startIcon={<Iconify icon="bi:plus-circle" color="primary" />}
          >
            <Typography>
              {String(translate('global.addNewProperty'))}
            </Typography>
          </Button>
        </div>
      )}
    </Box>
  );
}
