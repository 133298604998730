import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import {
  invalidateMarriageContractQueries,
  useDeleteAsset,
  useGetContractParties,
} from '#/api/marriageContract';
import { fSwissNumber } from '#/utils/formatNumber';
import ConfirmDelete from '#/components/pages/MarriageContract/steps/Estate/assets_before_marriage/confirm-delete';

type AssetDetailsProps = {
  asset: {
    id: number | string;
    description_good: string;
    approximate_value: string | number;
    storage_location: string;
    previous_owner: string | number;
  };
  onEdit?: () => void;
};

export default function AssetDetails({ asset, onEdit }: AssetDetailsProps) {
  const {
    id,
    description_good,
    approximate_value,
    storage_location,
    previous_owner,
  } = asset;

  const { mutateAsync: deleteAsset } = useDeleteAsset(id);
  const { data: contractParties } = useGetContractParties();

  const ownedBy = contractParties?.results?.find(
    (party) => party.id === Number(previous_owner)
  );

  const handleDelete = async () => {
    try {
      await deleteAsset(id).then(() => {
        invalidateMarriageContractQueries.getAssets();
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        my: 1,
        display: {
          sm: 'block',
          md: 'flex',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <InfoLabel label="global.description" value={description_good} />
        <InfoLabel
          label="global.formLabels.approximate_value"
          value={approximate_value}
        />
        <InfoLabel
          label="global.formLabels.storage_location"
          value={storage_location}
        />
        <InfoLabel
          label="global.formLabels.previous_owner"
          value={`${ownedBy?.first_name} ${ownedBy?.last_name}`}
        />
      </Box>
      <ActionButtons onEdit={onEdit} onDelete={handleDelete} />
    </Box>
  );
}

type InfoLabelProps = {
  label: string;
  value: string | number;
};

const InfoLabel = ({ label, value }: InfoLabelProps) => {
  const { translate } = useLocales();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography
        variant="subtitle1"
        noWrap
        fontStyle="italic"
        sx={{ width: '160px' }}
      >
        {String(translate(label))}:
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography variant="body1" noWrap>
          {typeof value === 'number' ? `CHF ${fSwissNumber(value)}` : value}
        </Typography>
      </Stack>
    </Stack>
  );
};

type ActionButtonsProps = {
  onEdit?: () => void;
  onDelete?: () => void;
};

const ActionButtons = ({ onEdit, onDelete }: ActionButtonsProps) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);

  const { translate } = useLocales();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        variant="outlined"
        size="small"
        onClick={onEdit}
        startIcon={
          <Iconify icon="octicon:pencil-16" sx={{ fontSize: '1.5rem' }} />
        }
      >
        <Typography variant="subtitle2" fontWeight="light">
          {String(translate('global.edit'))}
        </Typography>
      </Button>
      <Button
        variant="outlined"
        color="error"
        size="small"
        onClick={handleOpenConfirm}
        startIcon={
          <Iconify icon="octicon:trash-16" sx={{ fontSize: '1.5rem' }} />
        }
      >
        <Typography variant="subtitle2" fontWeight="light">
          {String(translate('global.delete'))}
        </Typography>
      </Button>

      <ConfirmDelete
        open={openConfirm}
        onClose={handleCloseConfirm}
        onDelete={onDelete}
      />
    </Stack>
  );
};
