import { Box, Button, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import AssetsForm from '#/components/pages/MarriageContract/steps/Estate/assets_before_marriage/assets-form';
import AssetDetails from '#/components/pages/MarriageContract/steps/Estate/assets_before_marriage/asset_details';
import { useGetAssets } from '#/api/marriageContract';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

export default function Assets() {
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editID, setEditID] = useState<number | null>(null);

  const { data: assets } = useGetAssets();

  const editAsset = assets?.results.find((asset) => asset.id === editID);

  const isInitial = assets?.results.length === 0;

  const handleAddMode = () => {
    setAddMode(true);
    setEditMode(false);
  };

  const handleEditMode = (assetId: number) => {
    setEditMode(true);
    setEditID(assetId);
    setAddMode(false);
  };

  const { translate } = useLocales();

  return (
    <Box>
      {isInitial && <AssetsForm />}
      {addMode && (
        <AssetsForm
          isAddMode={addMode}
          resetAddMode={() => setAddMode(false)}
        />
      )}
      {editMode && (
        <AssetsForm
          isEditMode={editMode}
          asset={editAsset}
          resetEdit={() => setEditMode(false)}
        />
      )}
      {!isInitial && !addMode && !editMode && (
        <div>
          {assets?.results.map((asset, index) => (
            <Box key={index}>
              <AssetDetails
                asset={asset}
                onEdit={() => handleEditMode(asset?.id)}
              />
              {index !== (assets?.results || []).length - 1 && <Divider />}
            </Box>
          ))}
          <Button
            onClick={handleAddMode}
            startIcon={<Iconify icon="bi:plus-circle" color="primary" />}
          >
            <Typography>{String(translate('global.addNewAsset'))}</Typography>
          </Button>
        </div>
      )}
    </Box>
  );
}
