import { Button, Paper, Stack, Typography } from '@mui/material';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type Props = {
  open: boolean;
  onClose: () => void;
  onDelete: (() => void) | undefined;
};

const ConfirmDelete = ({ open, onClose, onDelete }: Props) => {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle2" gutterBottom>
          {String(
            translate('marriageContract.stepTwo.confirmAssetDelete.title')
          )}
        </Typography>
        <Typography variant="caption">
          {String(
            translate('marriageContract.stepTwo.confirmAssetDelete.description')
          )}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography>
              {String(
                translate('marriageContract.stepTwo.confirmAssetDelete.cancel')
              )}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onDelete}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography>
              {String(
                translate('marriageContract.stepTwo.confirmAssetDelete.delete')
              )}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
};

export default ConfirmDelete;
