import Page from '#/components/shared/ui/Page';
import MarriageContractForm from '#/components/pages/MarriageContract/MarriageContractForm';

export default function MarriageContract() {
  return (
    <Page title="Ehevertrag">
      <MarriageContractForm />
    </Page>
  );
}
