import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';

export default function JointProvisionsOutput() {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text style={styles.title}>
        4{' '}
        {String(
          translate('marriageContract.stepFour.document.jointProvisions.title')
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>4.1</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.jointProvisions.section1.firstParagraph'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>4.2</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.jointProvisions.section2.firstParagraph'
          )
        )}
      </Text>
      <Text style={{ marginTop: 10 }}>
        {String(
          translate(
            'marriageContract.stepFour.document.jointProvisions.section2.secondParagraph'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>4.3</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.jointProvisions.section3.firstParagraph'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>4.4</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.jointProvisions.section4.firstParagraph'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>4.5</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.jointProvisions.section5.firstParagraph'
          )
        )}
      </Text>
    </View>
  );
}
