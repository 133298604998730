import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import useFormattedNumericInput from '#/hooks/useFormattedNumericInput';

export default function DisposableAmountForm() {
  const { translate } = useLocales();
  const { setValue, watch } = useFormContext();
  const { formattedValue, handleValueChange } = useFormattedNumericInput({
    name: 'disposal_fixed_amount',
    setValue,
    watch,
  });

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <RHFTextField
        name="disposal_fixed_amount"
        label={String(translate('global.formLabels.disposal_fixed_amount'))}
        onChange={handleValueChange}
        value={formattedValue}
      />
      <Typography>bzw</Typography>
      <RHFTextField
        name="disposal_percentage"
        label={String(translate('global.formLabels.disposal_percentage'))}
      />
    </Stack>
  );
}
