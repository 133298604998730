import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';

type SignatureOutputProps = {
  user: any;
  spouseInfo: any;
  address: string;
  spouseAddress: string;
};

export default function SignatureOutput({
  user,
  spouseInfo,
  address,
  spouseAddress,
}: SignatureOutputProps) {
  const { translate } = useLocales();
  return (
    <View style={styles.section2}>
      <Text style={{ marginTop: 40 }}>
        {String(
          translate('marriageContract.stepFour.document.signatures.title', {
            gender: String(
              translate(
                `marriageContract.stepFour.document.signatures.${
                  user?.gender === 'M' ? 'male' : 'female'
                }`
              )
            ),
          })
        )}
      </Text>
      <Text>
        {user?.first_name} {user?.last_name}
      </Text>
      <Text>{address}</Text>
      <Text
        style={{
          marginTop: 60,
        }}
      >
        {String(
          translate('marriageContract.stepFour.document.signatures.title', {
            gender: String(
              translate(
                `marriageContract.stepFour.document.signatures.${
                  user?.gender !== 'M' ? 'male' : 'female'
                }`
              )
            ),
          })
        )}
      </Text>
      <Text>
        {spouseInfo?.first_name} {spouseInfo?.last_name}
      </Text>
      <Text>{spouseAddress}</Text>
    </View>
  );
}
