import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';

export default function MarriageAgreementsOutput() {
  const { translate } = useLocales();
  return (
    <View style={styles.section2}>
      <Text style={styles.title}>
        2{' '}
        {String(
          translate(
            'marriageContract.stepFour.document.marriageContractAgreements.title'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>2.1</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.marriageContractAgreements.section1.firstParagraph'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>2.2</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.marriageContractAgreements.section2.firstParagraph'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>2.3</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.marriageContractAgreements.section3.firstParagraph'
          )
        )}
      </Text>
    </View>
  );
}
