import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';
import useLocales from '#/hooks/useLocales';

type Props = {
  user: {
    first_name: string;
    last_name: string;
    date_of_birth: string;
    civil_status: string;
  };
  address: string;
};

export default function IntroOutput({ user, address }: Props) {
  const { translate } = useLocales();

  return (
    <View style={styles.headSection}>
      <Text style={styles.title}>
        {String(translate('marriageContract.stepFour.document.title'))}
      </Text>
      <Text>
        {String(translate('marriageContract.stepFour.document.from'))}
      </Text>
      <View style={{ marginTop: 20 }}>
        <Text style={styles.title}>
          {`${user?.first_name} ${user?.last_name}`}
        </Text>
        <Text>
          {String(translate('marriageContract.stepFour.document.born'))}
          {fDate(user?.date_of_birth, 'dd. MMM yyyy', 'de')}
        </Text>
        <Text>
          {String(
            translate('marriageContract.stepFour.document.civil_status', {
              civil_status: translate(
                `marriageContract.stepFour.document.civilStatus.${user?.civil_status}`
              ),
            })
          )}
        </Text>
        <Text>
          {String(
            translate('marriageContract.stepFour.document.address', {
              address,
            })
          )}
        </Text>
      </View>
    </View>
  );
}
