import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';
import useLocales from '#/hooks/useLocales';
import { fSwissNumber } from '#/utils/formatNumber';

type DeterminationsOutputProps = {
  date_of_marriage: string;
  place_of_marriage: string;
  have_children: boolean;
  acquired_after_marriage: boolean;
  acquired_property_after_marriage: boolean;
  childrens?: any[];
  personalAssets?: any[];
  spouseAssets?: any[];
  properties?: {
    results: any[];
  };
};

export default function DeterminationsOutput({
  date_of_marriage,
  place_of_marriage,
  have_children,
  acquired_after_marriage,
  acquired_property_after_marriage,
  childrens,
  personalAssets,
  spouseAssets,
  properties,
}: DeterminationsOutputProps) {
  const { translate } = useLocales();

  const childrenFromCommonMarriage = childrens?.filter(
    (child) => child.from_commonMarriage === 'yes'
  );

  const childrenFromOtherMarriage = childrens?.filter(
    (child) => child.from_commonMarriage === 'no'
  );

  const getConjunction = (i: number, length: number) => {
    if (i === length - 2) {
      return ` ${String(translate('lastWill.stepFour.document.and'))}`;
    }
    if (i === length - 1) {
      return '';
    }
    return ',';
  };

  const removePrefix = (str: string) => str.substring(4);

  return (
    <View style={styles.section2}>
      <Text style={styles.title}>
        1{' '}
        {String(
          translate('marriageContract.stepFour.document.determinations.title')
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>1.1</Text>
      <Text>
        {have_children ? (
          <>
            {String(
              translate(
                'marriageContract.stepFour.document.determinations.section1.firstParagraphWithChildren',
                {
                  weddingDate: fDate(date_of_marriage, 'dd. MMM yyyy', 'de'),
                  weddingLocation: place_of_marriage,
                }
              )
            )}{' '}
            {childrenFromCommonMarriage &&
              childrenFromCommonMarriage?.length !== 0 && (
                <>
                  {childrenFromCommonMarriage?.length > 1
                    ? String(
                        translate(
                          'marriageContract.stepFour.document.determinations.section1.multipleCommonChildrenParagraph',
                          {
                            numberOfChildren:
                              childrenFromCommonMarriage?.length,
                          }
                        )
                      )
                    : String(
                        translate(
                          'marriageContract.stepFour.document.determinations.section1.oneCommonChildParagraph'
                        )
                      )}
                  {childrenFromCommonMarriage?.map((child, index) => (
                    <Text key={index}>
                      {index !== 0 && ' '}
                      {child.first_name} {child.last_name}{' '}
                      {String(
                        translate(
                          `marriageContract.stepFour.document.determinations.section1.childBirthday`,
                          {
                            birthday: fDate(
                              child?.date_of_birth,
                              'dd. MMM yyyy',
                              'de'
                            ),
                          }
                        )
                      )}
                      {getConjunction(
                        index,
                        childrenFromCommonMarriage?.length
                      )}
                    </Text>
                  ))}
                  , hervorgegangen.
                </>
              )}
            {'\n'}
            {childrenFromOtherMarriage &&
              childrenFromOtherMarriage?.length !== 0 && (
                <>
                  {childrenFromOtherMarriage?.length > 1
                    ? String(
                        translate(
                          'marriageContract.stepFour.document.determinations.section1.multipleChildrenOutsideMarriage',
                          {
                            numberOfChildren: childrenFromOtherMarriage?.length,
                          }
                        )
                      )
                    : String(
                        translate(
                          'marriageContract.stepFour.document.determinations.section1.oneChildOutsideMarriage'
                        )
                      )}
                  {childrenFromOtherMarriage?.map((child, index) => (
                    <Text key={index}>
                      {index !== 0 && ' '}
                      {child.first_name} {child.last_name}{' '}
                      {String(
                        translate(
                          `marriageContract.stepFour.document.determinations.section1.childBirthday`,
                          {
                            birthday: fDate(
                              child?.date_of_birth,
                              'dd. MMM yyyy',
                              'de'
                            ),
                          }
                        )
                      )}
                      {getConjunction(index, childrenFromOtherMarriage?.length)}
                    </Text>
                  ))}
                  , hervorgegangen.
                </>
              )}
          </>
        ) : (
          String(
            translate(
              'marriageContract.stepFour.document.determinations.section1.firstParagraphWithoutChildren',
              {
                weddingDate: fDate(date_of_marriage, 'dd. MMM yyyy', 'de'),
                weddingLocation: place_of_marriage,
              }
            )
          )
        )}
      </Text>
      {!childrenFromOtherMarriage?.length && (
        <Text>
          {String(
            translate(
              'marriageContract.stepFour.document.determinations.section1.noChildrenOutsideMarriage'
            )
          )}
        </Text>
      )}
      <Text style={{ marginBottom: 4, marginTop: 10 }}>1.2</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.determinations.section2.noMarriageContract'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>1.3</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.determinations.section3.participationAgreement'
          )
        )}
      </Text>
      <Text style={{ marginBottom: 4, marginTop: 10 }}>1.4</Text>
      <Text>
        {String(
          translate(
            'marriageContract.stepFour.document.determinations.section4.personalAssets'
          )
        )}
      </Text>
      {personalAssets?.length && personalAssets?.length > 0 && (
        <View style={{ marginTop: 4, display: 'flex', flexDirection: 'row' }}>
          <View>
            <Text>
              {String(
                translate(
                  'marriageContract.stepFour.document.determinations.section4.pointA'
                )
              )}
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            {personalAssets?.map((asset, index) => (
              <Text key={index}>
                {asset.description_good}, CHF{' '}
                {fSwissNumber(asset.approximate_value)},{' '}
                {asset.storage_location}
              </Text>
            ))}
          </View>
        </View>
      )}
      {spouseAssets?.length && spouseAssets?.length > 0 && (
        <View style={{ marginTop: 4, display: 'flex', flexDirection: 'row' }}>
          <View>
            <Text>
              {String(
                translate(
                  'marriageContract.stepFour.document.determinations.section4.pointB'
                )
              )}
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            {spouseAssets?.map((asset, index) => (
              <Text key={index}>
                {asset.description_good}, CHF{' '}
                {fSwissNumber(asset.approximate_value)},{' '}
                {asset.storage_location}
              </Text>
            ))}
          </View>
        </View>
      )}
      {acquired_after_marriage && (
        <>
          <Text style={{ marginBottom: 4, marginTop: 10 }}>1.5</Text>
          <Text>
            {String(
              translate(
                'marriageContract.stepFour.document.determinations.section5.otherAssets'
              )
            )}
            {acquired_property_after_marriage &&
              ` ${String(
                translate(
                  'marriageContract.stepFour.document.determinations.section5.evenEstates'
                )
              )}`}
            {acquired_property_after_marriage &&
              properties?.results?.map((property, index) => (
                <>
                  {index !== 0 && ' '}
                  {String(
                    translate(
                      `marriageContract.stepFour.document.determinations.section5.${property.property_type}`
                    )
                  )}{' '}
                  an der {property.address.street}{' '}
                  {property.address.house_number}, {property.address.zip_code}{' '}
                  {property.address.city}{' '}
                  {String(
                    translate(
                      `global.${property.address.country.toLowerCase()}`
                    )
                  )}
                  {getConjunction(index, properties?.results?.length)}
                </>
              ))}
            {` ${String(
              translate(
                'marriageContract.stepFour.document.determinations.section5.otherAssetsContinued'
              )
            )}`}
          </Text>
        </>
      )}
      {!acquired_after_marriage && acquired_property_after_marriage && (
        <>
          <Text style={{ marginBottom: 4, marginTop: 10 }}>1.5</Text>
          <Text>
            {String(
              translate(
                'marriageContract.stepFour.document.determinations.section5.nonAcquiredAfterMarriage',
                {
                  assetsQuantity: translate(
                    `marriageContract.stepFour.document.determinations.section5.${
                      properties?.results?.length === 1 ? 'singular' : 'plural'
                    }`
                  ),
                }
              )
            )}
            {properties?.results?.map((property, index) => (
              <>
                {index !== 0 && ' '}
                {removePrefix(
                  String(
                    translate(
                      `marriageContract.stepFour.document.determinations.section5.${property.property_type}`
                    )
                  )
                )}{' '}
                an der {property.address.street} {property.address.house_number}
                , {property.address.zip_code} {property.address.city}{' '}
                {property.address.country}
                {getConjunction(index, properties?.results?.length)}
              </>
            ))}
          </Text>
        </>
      )}
    </View>
  );
}
